var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c("action-menu", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showActionMenu,
            expression: "showActionMenu",
          },
        ],
        attrs: { slot: "top", caseId: _vm.caseId },
        on: { action: _vm.handleAction },
        slot: "top",
      }),
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.caseTitle, fixed: "" },
          slot: "header",
        },
        [
          _c("fb-button", {
            attrs: {
              slot: "left",
              icon: "back",
              size: "small",
              type: "primary",
            },
            on: { click: _vm.goBack },
            slot: "left",
          }),
          _c("fb-button", {
            attrs: {
              slot: "right",
              icon: "menu",
              size: "small",
              type: "primary",
            },
            on: {
              click: function ($event) {
                _vm.showActionMenu = !_vm.showActionMenu
              },
            },
            slot: "right",
          }),
        ],
        1
      ),
      _c("remote-js", {
        attrs: { src: "//oss.farbun.com/vis-timeline-graph2d.min.js" },
      }),
      _c("router-view", { staticClass: "casedetail-router-view" }),
      _c("van-dialog", {
        attrs: {
          title: _vm.actionTitle,
          "show-cancel-button": true,
          message: "该操作不可撤销，确认是否继续",
        },
        on: { confirm: _vm.confirmAction, cancel: _vm.cancelAction },
        model: {
          value: _vm.handleActionConfirm,
          callback: function ($$v) {
            _vm.handleActionConfirm = $$v
          },
          expression: "handleActionConfirm",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }