<template>
  <fb-page>
    <action-menu
      slot="top"
      :caseId="caseId"
      v-show="showActionMenu"
      @action="handleAction"
    ></action-menu>
    <fb-header slot="header" :title="caseTitle" fixed>
      <fb-button slot="left" icon="back" @click="goBack" size="small" type="primary">
      </fb-button>
      <fb-button
        slot="right"
        icon="menu"
        size="small"
        type="primary"
        @click="showActionMenu = !showActionMenu"
      >
      </fb-button>
    </fb-header>

    <remote-js src="//oss.farbun.com/vis-timeline-graph2d.min.js" />

    <router-view class="casedetail-router-view"></router-view>
    <!-- 重命名弹出框 -->
    <van-dialog
      v-model="handleActionConfirm"
      :title="actionTitle"
      :show-cancel-button="true"
      @confirm="confirmAction"
      @cancel="cancelAction"
      message="该操作不可撤销，确认是否继续"
    >
    </van-dialog>
  </fb-page>
</template>

<script>
import { mapState } from 'vuex'

import ActionMenu from './components/actionMenu'

export default {
  name: 'case-detail',
  components: {
    ActionMenu,
  },
  // mixins: [goBackMixin],
  data() {
    return {
      // 点击菜单出现确认弹出层
      handleActionConfirm: false,
      // 菜单确认类型
      actionParam: '',
      // 弹出菜单层title
      actionTitle: '',
      active: 0,
      showActionMenu: false,
    }
  },
  computed: {
    ...mapState('caseDetail', ['caseTitle']),
    caseId() {
      return this.$route.params.caseId
    },
    isTimelinePage() {
      if (this.$route.name === 'casedetail-timeline') {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    getEditCase() {
      const caseId = this.caseId
      this.$router.push({ path: '/createcase', query: { caseId } })
    },
    // 点击菜单操作
    handleAction(action) {
      // hide 为点击其他区域 关闭隐藏菜单和蒙层
      if (action === 'hide') {
        this.showActionMenu = false
      } else if (action === 'edit') {
        // 编辑操作
        this.getEditCase()
      } else if (action === 'sendCase') {
        this.$router.push({ name: 'customer' })
      } else if (action === 'remind') {
        const nodeData = window.rawCaseData
        this.$store.commit('remindForm/openRemindForm', {
          status: false,
          nodeData,
        })
      } else {
        if (action === 'closeCase') {
          this.actionTitle = '结案'
        } else if (action === 'deleteCase') {
          this.actionTitle = '删除'
        } else if (action === 'setAsStd') {
          this.actionTitle = '共享'
        }
        this.actionParam = action
        this.handleActionConfirm = true
      }
    },
    confirmAction() {
      this.actionDone(this.actionParam)
    },
    cancelAction() {
      // 重置弹出框标题和参数
      this.actionTitle = ''
      this.actionParam = ''
      this.$toast('操作已取消')
    },
    actionDone(action) {
      const caseId = this.caseId
      if (action === 'closeCase') {
        // 结案
        this.$axios
          .post(`${this.$base}/lts/case/complete?caseId=${caseId}`)
          .then((res) => {
            // todo 结案后的操作
            this.$toast('结案成功')
          })
      } else if (action === 'deleteCase') {
        // 删除
        this.$axios.post(`${this.$base}/lts/case/delete?caseId=${caseId}`).then((res) => {
          this.$toast('删除成功 即将返回案件列表页')
          const iOSUA = 'fb-iOS-Webview'
          const AndroidUA = 'fb-Android-Webview'
          const flutterUA = 'fb-flutter-Webview'
          const ua = navigator.userAgent
          if (ua === AndroidUA) {
            try {
              // eslint-disable-next-line
              const ua = navigator['userAgent']
              if (ua === 'fb-Android-Webview') {
                // eslint-disable-next-line
                AND2JS.refreshCase()
                // eslint-disable-next-line
                AND2JS.back()
              } else if (ua === 'fb-flutter-Webview') {
                try {
                  window.refreshCase.postMessage('{}')
                  window.back.postMessage('{}')
                } catch (err) {
                  console.log('refreshCase.postMessage,back.postMessage', err)
                }
              } else {
                window.webkit.messageHandlers.back.postMessage({})
              }
            } catch {
              this.$notify('没有可以返回的页面')
            }
          } else if (ua === iOSUA) {
            try {
              // eslint-disable-next-line
              window.webkit.messageHandlers.refreshCase.postMessage({})
              window.webkit.messageHandlers.back.postMessage({})
            } catch {
              this.$notify('没有可以返回的页面')
            }
          } else if (ua === flutterUA) {
            try {
              // eslint-disable-next-line
              window.refreshCase.postMessage('{}')
              window.back.postMessage('{}')
            } catch (err) {
              this.$notify('没有可以返回的页面')
              console.log('refreshCase.postMessage,back.postMessage', err)
            }
          } else {
            this.$router.back()
          }
        })
      } else if (action === 'setAsStd') {
        // 共享
        this.$axios.post(`${this.$base}/lts/criterion/save`, { caseId }).then((res) => {
          this.$toast('共享成功')
          // todo 共享后的操作
        })
      } else {
        console.log('没有匹配的按钮')
      }
      // 重置弹出框标题和参数
      this.actionTitle = ''
      this.actionParam = ''
    },
    /*
     * * 如果是原生端 则直接退出webview
     * * 否则尝试路由回退
     */
    goBack() {
      const iOSUA = 'fb-iOS-Webview'
      const AndroidUA = 'fb-Android-Webview'
      const flutterUA = 'fb-flutter-Webview'
      const ua = navigator.userAgent
      if (ua === AndroidUA) {
        try {
          // eslint-disable-next-line
          AND2JS.back()
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else if (ua === flutterUA) {
        try {
          // eslint-disable-next-line
          window.back.postMessage('{}')
        } catch (err) {
          this.$notify('没有可以返回的页面')
          console.log('back.postMessage', err)
        }
      } else if (ua === iOSUA) {
        try {
          // eslint-disable-next-line
          window.webkit.messageHandlers.back.postMessage({})
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else {
        this.$router.back()
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@import 'https://mobile.oss.farbun.com/third-party/vis-timeline-graph2d.min.css';
.casedetail-router-view
  max-height calc(100% - 44px)
  overflow hidden
  // 调试文书软键盘
  position absolute
  width 100%
  height 100%
</style>
