<template>
  <div>
    <div class="menu-mask"
         @click="showMainContent"></div>
    <div class="menu-select">
      <ul class="van-hairline--surround">
        <li v-for="(action, index) in actions"
            :key="index"
            @click="selectAction(action.action)">
          <!-- <i :class="'iconfont icon-' + action.icon"></i>  -->
          {{action.name}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    caseId: String
  },
  name: 'action-menu',
  data () {
    return {
      action: '',
      actions: [
        {
          name: '案件结案',
          action: 'closeCase',
          icon: 'jiean'
        },
        {
          name: '删除案件',
          action: 'deleteCase',
          icon: 'shanchu'
        },
        {
          name: '经验复用',
          action: 'setAsStd',
          icon: 'share1'
        },
        {
          name: '编辑信息',
          action: 'edit',
          icon: 'edit'
        },
        {
          name: '创建日程',
          action: 'remind',
          icon: 'tixing'
        },
        {
          name: '发送客户',
          action: 'sendCase',
          icon: 'fasong1'
        }
      ]
    }
  },
  methods: {
    selectAction (action) {
      this.selected = action
      this.$emit('action', action)
    },
    showMainContent () {
      this.$emit('action', 'hide')
    }
  }
}
</script>

<style lang="stylus" scoped>
.menu-mask
  position absolute
  height calc(100% - 44px)
  width 100%
  top 44px
  background rgba(0, 0, 0, 0.15)
  z-index 104
.menu-select
  position fixed
  right 0
  top 29px
  z-index 1001
  padding 5px
.menu-select ul
  background #fff
  border-radius 10px
  width 118px
  overflow hidden
  color #c7c8c9
  padding 10px 0
  // &:after
  // position absolute
  // right 20px
  // top 5px
  // content ''
  // display inline-block
  // width 10px
  // height 10px
  // background #fff
  // transform rotate(45deg)
  // border-top 1px solid #eee
  // border-left 1px solid #eee
  li
    display flex
    height 35px
    justify-content center
    padding 0 20px
    color #333
    font-size 14px
    color #444
    line-height 35px
    i
      margin-right 12px
      width 14px
</style>
