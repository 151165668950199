var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "menu-mask", on: { click: _vm.showMainContent } }),
    _c("div", { staticClass: "menu-select" }, [
      _c(
        "ul",
        { staticClass: "van-hairline--surround" },
        _vm._l(_vm.actions, function (action, index) {
          return _c(
            "li",
            {
              key: index,
              on: {
                click: function ($event) {
                  return _vm.selectAction(action.action)
                },
              },
            },
            [_vm._v(" " + _vm._s(action.name) + " ")]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }